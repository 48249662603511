import { Field, Formik } from 'formik';
import { Observer, useObserver } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { CategoriesContext } from '../../contexts/Categories';
import { ChatsContext } from '../../contexts/Chats';
import { UsersContext } from '../../contexts/Users';
import { db } from '../../firebase';
import * as CSS from '../staff/elements/EditUserPersonalInfoForm';

const SetCategoryModal = React.forwardRef(({ closeModal }, ref) => {
  const usersStore = useContext(UsersContext);
  const categoriesStore = useContext(CategoriesContext);
  const chatsStore = useContext(ChatsContext);
  const loggedInUser = usersStore.getLoggedInUser();

  const initialValues = {
    category: loggedInUser.selectedCategory,
  };

  const handleSubmit = async ({ category }) => {
    chatsStore.clearSelectedChat();
    await db.collection('users').doc(loggedInUser.id).update({
      selectedCategory: category,
    });
    closeModal();
  };

  const categoryOptions = categoriesStore.getUsersCategories(
    loggedInUser.categories
  );

  return useObserver(() => (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ dirty, isSubmitting }) => (
        <Observer>
          {() => (
            <CSS.Form data-testid="SetCategoryModal">
              <CSS.StaffFormHeaderRow>
                <CSS.StaffFormHeader>Change Category</CSS.StaffFormHeader>
                <CSS.StaffFormCloseButton onClick={closeModal} type="button">
                  <CSS.StaffCloseIcon />
                </CSS.StaffFormCloseButton>
              </CSS.StaffFormHeaderRow>
              <CSS.FieldGroup>
                <Field
                  select
                  name="category"
                  id="category"
                  label="Select a category"
                  component={CSS.StaffTextFieldInput}
                >
                  {categoryOptions.map((category) => {
                    return (
                      <CSS.StaffMenuItem key={category.id} value={category.id}>
                        {category.categoryName}
                      </CSS.StaffMenuItem>
                    );
                  })}
                </Field>
              </CSS.FieldGroup>
              <CSS.StaffFooterRow>
                <CSS.SubmitChangesButton
                  data-testid="SetCategoryModal-submit"
                  type="submit"
                  disabled={!dirty || isSubmitting}
                >
                  Save
                </CSS.SubmitChangesButton>
              </CSS.StaffFooterRow>
            </CSS.Form>
          )}
        </Observer>
      )}
    </Formik>
  ));
});

export default SetCategoryModal;
